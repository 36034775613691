import {fadeIn, fadeOut} from "../../../default/global/js/utilities/fade";
import {changeRequiredAttributes} from "../../../default/global/js/order";
import {checkFilledInput, focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {getScrollbarWidth} from "../../../default/global/js/utilities/functions";
import {addTableWrapper} from "../../../default/global/js/tableWrapper";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import EventHandler from "../../../default/global/js/dom/event-handler";
import { setCookie, getCookie } from "../../../default/global/js/utilities/cookie";


function showModalBackground(backdropClickable = true, modal = null) {
    if (!document.querySelector('.modalBg')) {
        let modalBg = document.createElement('div');
        modalBg.classList.add('modalBg');
        document.body.appendChild(modalBg);
        document.body.classList.add('modal-open');

        if (window.outerWidth > GLOBALS.gridBreakpoints.sm) {
            document.body.style.paddingRight = getScrollbarWidth() + "px";
        }

        fadeIn(modalBg, 400);

        if (backdropClickable) {
            modalBg.addEventListener('click', function () {
                closeModal(modal);
            });
        }
    }
}

function removeModalBackground() {
    if (!document.querySelectorAll('.modal.open').length) {
        for (let modalbg of document.querySelectorAll('.modalBg')) {
            fadeOut(modalbg, 400, "linear", function () {
                modalbg.remove();
                document.body.classList.remove('modal-open');
                document.body.style.paddingRight = "";
            });
        }
    }
}

function resetModalStyles(modal) {
    modal.style.zIndex = "";
}

export function generateNewModal(templateID) {
    const template = document.getElementById(templateID);
    if (template) {
        const newModal = document.body.appendChild(template.content.cloneNode(true).querySelector('.modal'));
        newModal.setAttribute("id", "newModal");
        return newModal;
    } else {
        console.error("Cannot find Modal Template");

        return false;
    }
}

function disablePrevOpenedModals(modals,currentModal){
    for(let modal of modals){
        if(modal !== currentModal){
            modal.classList.add('modal--disabled');
        }
    }
}

function enableLastOpenedModal(){
    let allOpenedModals = document.querySelectorAll('.modal.open');
    if(allOpenedModals){
        let index = allOpenedModals.length - 1;
        if(index >= 0){
            for(let modal of allOpenedModals){
                if(parseInt(modal.dataset.index) === index){
                    modal.classList.remove("modal--disabled");
                }
            }
        }else{
            document.removeEventListener("keydown", eventEscapeCloseModal);
        }
    }
}

function eventEscapeCloseModal(event) {
    if(event.key === "Escape"){
        const allOpenedModals = document.querySelectorAll('.modal.open');
        closeModal(allOpenedModals[allOpenedModals.length - 1]);
    }
}

export function closeModal(modal = null) {
    let modals = [];
    if (modal === null) {
        modals = document.querySelectorAll('.modal');
    } else {
        modals.push(modal);
    }

    if (modals) {
        for (let modal of modals) {
            resetModalStyles(modal);
            modal.classList.remove('open');
            modal.classList.remove('show');
            let frames = modal.querySelectorAll('iframe,video');
            for (let frame of frames) {
                let src = frame.getAttribute('src');
                if (src) {
                    frame.setAttribute('src', '');
                    frame.setAttribute('src', src);
                }
            }
            enableLastOpenedModal();

            setTimeout(function () {
                modal.style.display = "";
                removeModalBackground();
            }, 200);
        }
    }

    EventHandler.trigger(modal, 'hidden.modal');

}

export function showModal(modal, callback = () => {}, options = {}) {
    if (!modal) return;

    let allOpenedModals = document.querySelectorAll('.modal.open');
    let index = allOpenedModals.length;

    const defaultOptions = {
        type: "modal",
        direction: "",
        backdropClickable: true
    };

    options = {...defaultOptions, ...options};

    if (options.type === 'flyout') {
        modal.classList.add('modal--flyout');
        if (options.direction === 'left') {
            modal.classList.add(`modal--flyoutLeft`);
        } else {
            modal.classList.add(`modal--flyoutRight`);
        }
    }

    modal.setAttribute('data-index', index);
    modal.style.display = "flex";
    showModalBackground(options.backdropClickable, modal);

    setTimeout(function () {
        disablePrevOpenedModals(allOpenedModals, modal);
        modal.classList.add('open');
        if (index > 0) {
            modal.style.zIndex = parseInt(getComputedStyle(modal).zIndex || 1050) + index;
        }
        setTimeout(function () {
            changeRequiredAttributes(modal.querySelectorAll('.isRequiredVisible'));
        }, 200);
        focusInputGroups(modal.querySelectorAll('.inputGroup input,.inputGroup select,.inputGroup textarea'));
        callback();
    }, 200);

    modal.addEventListener('mousedown', function (e) {
        if (options.backdropClickable && e.target.closest(".modal__dialog") === null) {
            closeModal(modal);
        }
        if (e.target.closest("[data-close=\"modal\"]") !== null) {
            closeModal(modal);
        }
    });

    if (index === 0) {
        document.addEventListener("keydown", eventEscapeCloseModal);
    }
}

export function triggerModal(query = document.querySelectorAll('[data-modal]')) {

    for (let modalTrigger of query) {
        modalTrigger.addEventListener('click', function (e) {
            showModal(document.getElementById(modalTrigger.dataset.modal));
            e.preventDefault();
        });
    }

    for (let openModalOnLoad of document.querySelectorAll('.modal--openOnLoad')) {
        showModal(openModalOnLoad);
    }
}

export function excludeModal(query = document.querySelectorAll('.modal:not(.modal-stay)')) {
    for (let modal of query) {
        document.body.append(modal);
    }
}

export function triggerOpenLinkInModal(cssselector = 'js-openLinkInModal') {
    document.addEventListener('click', function (e) {
        let target = false;
        if(e.target.classList.contains(cssselector)){
            target = e.target;
        }
        if(e.target.closest("a") && e.target.closest("a").classList.contains(cssselector)){
            target = e.target.closest("a");
        }
        if (target) {
            let modalTrigger = target;
            let href = modalTrigger.href;
            let title = modalTrigger.title;
            if (href) {
                const modal = generateNewModal("modalTemplate");
                if (modal) {
                    let modalClass = modalTrigger.dataset.modalClass;
                    if(modalClass){
                        modal.classList.add(modalClass);
                    }
                    showModal(modal);
                    fetch(modalTrigger.href)
                        .then((response) => {
                            if (!response.ok) {
                                throw "Could not load content";
                            }
                            return response.text();
                        })
                        .then((data) => {
                            let doc = new DOMParser().parseFromString(data, "text/html");
                            let contents = doc.querySelectorAll(".loadContentInModal");
                            let modalBody = modal.querySelector('.modal__body');
                            let modalTitle = modal.querySelector('.modal__title');
                            if (modalTitle && title) {
                                modalTitle.innerHTML = title;
                            }
                            if (contents && modalBody) {
                                modal.classList.remove("modal--loading");
                                modalBody.innerHTML = "";
                                for (let content of contents) {
                                    modalBody.innerHTML = modalBody.innerHTML + content.innerHTML;
                                }

                                focusInputGroups(modalBody.querySelectorAll('.inputGroup input,.inputGroup select,.inputGroup textarea'));
                                addTableWrapper(modalBody.querySelectorAll('.textcontent table, .modal__body table, .slidecontent table'));
                                initSlidecontents(modalBody.querySelectorAll('.slidecontentItem__headline'));
                            } else {
                                window.location.href = href;
                            }
                        })
                        .catch((e) => {
                            console.error('Error:', e);
                        });
                    e.preventDefault();
                }
            }
        }
    });
}

export function triggerConfirmModalLink(cssselector = 'js-confirmModalLink') {
    document.addEventListener('click', function (e) {
        let target = false;
        if(e.target.classList.contains(cssselector)){
            target = e.target;
        }
        if(e.target.closest("a") && e.target.closest("a").classList.contains(cssselector)){
            target = e.target.closest("a");
        }
        if (target) {
            let modalTrigger = target;
            let href = modalTrigger.href;
            let text = modalTrigger.dataset.text;
            let confirmBtnText = modalTrigger.dataset.confirmBtn;
            let confirmBtnClass = modalTrigger.dataset.confirmBtnClass;
            if (href) {
                const modal = generateNewModal("confirmModalTemplate");
                if (modal) {
                    let modalBody = modal.querySelector('.modal__body');
                    let modalConfirmBtn = modal.querySelector('.js-confirmModalButton');
                    if (modalBody && text) {
                        modalBody.innerHTML = text;
                    }
                    if (modalConfirmBtn && confirmBtnText) {
                        modalConfirmBtn.innerText = confirmBtnText;
                        if (confirmBtnClass) {
                            modalConfirmBtn.classList.remove("button--primary");
                            modalConfirmBtn.classList.add(confirmBtnClass);
                        }

                        modalConfirmBtn.addEventListener("click", function () {
                            console.log(href);
                            window.location.href = href;
                        });
                    }
                    showModal(modal);
                    e.preventDefault();
                }
            }
        }
    });
}

export function triggerOpenTextInModal(cssselector = 'js-openTextInModal') {
    document.addEventListener('click', function (e) {
        let target = false;
        if(e.target.classList.contains(cssselector)){
            target = e.target;
        }
        if(e.target.closest("a") && e.target.closest("a").classList.contains(cssselector)){
            target = e.target.closest("a");
        }
        if (target) {
            let modalTrigger = target;
            let text = "no text";
            let title = "";

            if(modalTrigger.dataset.text){
                text = modalTrigger.dataset.text;
            }

            if(modalTrigger.dataset.headline){
                title = modalTrigger.dataset.headline;
            }
            const modal = generateNewModal("modalTemplate");
            if (modal) {
                modal.classList.remove("modal--loading");
                let modalClass = modalTrigger.dataset.modalClass;
                if(modalClass){
                    modal.classList.add(modalClass);
                }
                let modalBody = modal.querySelector('.modal__body');
                let modalTitle = modal.querySelector('.modal__title');
                if (modalTitle) {
                    modalTitle.innerHTML = title;
                }
                if (modalBody) {
                    modalBody.innerHTML = "<div>"+text+"</div>";
                }
                showModal(modal);
                e.preventDefault();
            }
        }
    });
}


export function handleOnboardingModal() {
    const modalId = "itemcardOnboarding";
    const cookieName = "itemcardOnboardingShown";
    const hasSeenOnboarding = getCookie(cookieName);

    const modal = document.getElementById(modalId);
    if (!modal) return;

    if (!hasSeenOnboarding) {
        // Modal anzeigen, Klick auf Hintergrund deaktivieren
        showModal(modal, () => {
            playModalVideo(modal); // 🎥 Video automatisch starten
        }, { backdropClickable: false });

        // Modal-Close-Button ausblenden
        const closeButton = modal.querySelector(".modal__close");
        if (closeButton) closeButton.style.display = "none";

        // Sicherstellen, dass der Hintergrund keine Pointer-Events zulässt
        const modalBg = document.querySelector(".modalBg");
        if (modalBg) modalBg.style.pointerEvents = "none";

        // Button nach 5 Sekunden hinzufügen
        setTimeout(() => {
            const confirmButton = document.createElement("button");
            confirmButton.textContent = "Verstanden";
            confirmButton.classList.add("button", "button--primary");
            confirmButton.style.marginTop = "20px";

            const modalFooter = modal.querySelector(".modal__footer");
            if (modalFooter) {
                modalFooter.appendChild(confirmButton);

                // 🔥 Automatisch zum Footer scrollen
                modalFooter.scrollIntoView({ behavior: "smooth", block: "end" });
            }

            confirmButton.addEventListener("click", () => {
                setCookie(cookieName, "true");
                closeModal(modal);
                stopModalVideo(modal); // 🎥 Video stoppen, wenn Modal geschlossen wird

                // Hintergrund-Klick nach Schließen wieder aktivieren
                const modalBgAfter = document.querySelector(".modalBg");
                if (modalBgAfter) modalBgAfter.style.pointerEvents = "auto";
            });

        }, 5000);
    } else {
        // Standardverhalten wiederherstellen, wenn Cookie gesetzt ist
        const closeButton = modal.querySelector(".modal__close");
        if (closeButton) closeButton.style.display = "";

        const modalBg = document.querySelector(".modalBg");
        if (modalBg) modalBg.style.pointerEvents = "auto";
    }
}

/**
 * 🎥 Startet das Video im Modal automatisch
 */
function playModalVideo(modal) {
    if (!modal) return;

    // 🎬 Direktes HTML5-Video starten
    const video = modal.querySelector("video");
    if (video) {
        video.play().catch(error => console.warn("Video konnte nicht automatisch abgespielt werden:", error));
    }

    // 📺 Falls ein eingebettetes YouTube/iframe-Video vorhanden ist, Autoplay aktivieren
    const iframe = modal.querySelector("iframe");
    if (iframe && iframe.src.includes("youtube.com")) {
        let src = iframe.src;
        if (!src.includes("autoplay=1")) {
            src += (src.includes("?") ? "&" : "?") + "autoplay=1";
            iframe.src = src; // Autoplay aktivieren
        }
    }
}

/**
 * ⏹ Stoppt das Video, wenn das Modal geschlossen wird
 */
function stopModalVideo(modal) {
    if (!modal) return;

    // ⏹ HTML5-Video pausieren und zurücksetzen
    const video = modal.querySelector("video");
    if (video) {
        video.pause();
        video.currentTime = 0; // Startet von vorne, wenn erneut geöffnet
    }

    // ⏹ Falls es sich um ein YouTube-Video handelt, die URL zurücksetzen
    const iframe = modal.querySelector("iframe");
    if (iframe && iframe.src.includes("youtube.com")) {
        let src = iframe.src.replace(/&?autoplay=1/, ""); // Autoplay entfernen
        iframe.src = src; // YouTube-Video zurücksetzen
    }
}
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';
import {getOptionsFromJsonString} from "../../../../default/global/js/utilities/options";

export function collectionNewsSwiper(query = document.querySelectorAll('.newsSwiper.swiper'),options = {}) {
    let defaultOptions = {
        breakpointItems: {
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xlg: 3
        }
    }

    defaultOptions = {...defaultOptions, ...options};

    for (let newsSwiper of query) {

        let options = getOptionsFromJsonString(newsSwiper.getAttribute('data-options'), defaultOptions);
        let childrenCount = newsSwiper.querySelectorAll('.swiper-wrapper > *').length;
        let loop = false;


        let breakpoints = {
            xs: {
                items: options.breakpointItems.xs,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.xs),
            },
            sm: {
                items: options.breakpointItems.sm,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.sm),
            },
            md: {
                items: options.breakpointItems.md,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.md),
            },
            lg: {
                items: options.breakpointItems.lg,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.lg),
            },
            xlg: {
                items: options.breakpointItems.xlg,
                autoplay: childrenCount > Math.ceil(options.breakpointItems.xlg),
            }
        };

        Swiper.use([Autoplay, Navigation, Pagination]);
        new Swiper(newsSwiper, {
            loop: loop,
            speed: 300,
            spaceBetween: 16,
            navigation: {
                nextEl: newsSwiper.querySelector('.swiper-button-next'),
                prevEl: newsSwiper.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: breakpoints.xs.items,
                    slidesPerGroup: Math.floor(breakpoints.xs.items)
                },
                768: {
                    slidesPerView: breakpoints.sm.items,
                    slidesPerGroup: Math.floor(breakpoints.sm.items),
                },
                1024: {
                    slidesPerView: breakpoints.md.items,
                    slidesPerGroup: Math.floor(breakpoints.md.items),
                },
                1250: {
                    slidesPerView: breakpoints.lg.items,
                    slidesPerGroup: Math.floor(breakpoints.lg.items),
                },
                1540: {
                    slidesPerView: breakpoints.xlg.items,
                    slidesPerGroup: Math.floor(breakpoints.xlg.items),
                }
            }
        });
    }
}
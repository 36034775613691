import { setCookie, getCookie } from "../../../default/global/js/utilities/cookie";

export function handleAsideElement(asideId, buttonClass, cookieName, cookieDays = 7) {
    function init() {
        const asideElement = document.getElementById(asideId);
        const button = document.querySelector(`.${buttonClass}`);

        if (!asideElement) {
            return;
        }
        if (!button) {
            return;
        }

        if(button) {
            button.classList.add('d-none');
            setTimeout(() => {
                button.classList.remove('d-none');
            },13000);
        }


        button.addEventListener('click', function () {
            asideElement.style.display = 'none';
            setCookie(cookieName, 'true', cookieDays);
        });

        const hideAside = getCookie(cookieName);
        if (hideAside === 'true') {
            asideElement.style.display = 'none';
        }
    }

    init();
}
import anime from "animejs";

function showFlashmessage(msg,delay = 200){
    msg.classList.add("is-active");
    anime({
        targets: msg,
        opacity: 1,
        duration: 200,
        easing: 'easeInQuad',
        delay: delay,
        complete: function () {
            msg.classList.add("is-finished");
        }
    });
}

function hideFlashmessage(msg,delay = 200){
    msg.classList.remove("is-finished");
    anime({
        targets: msg,
        opacity: 0,
        duration: 200,
        easing: 'easeInQuad',
        delay: delay,
        complete: function () {
            msg.classList.remove("is-active");
        }
    });
}
function generateNewFlashmessage(templateID = "flashMessageTemplate") {
    const template = document.getElementById(templateID);
    if (template) {
        const flashWrapper = document.getElementById('flashMessages');
        if(flashWrapper){
            return flashWrapper.appendChild(template.content.cloneNode(true).querySelector('.flashMessage'));
        }
    } else {
        console.error("Cannot find Flashmessage Template");
    }
    return false;
}

export function getNewFlashmessage(msg = "", type = 0){
    let iconClass = "icon icon-exclamation";
    let flashClass = "flashMessage--info";
    switch (type){
        case 1:
            iconClass = "icon icon-check";
            flashClass = "flashMessage--success";
            break;
        case 2:
            flashClass = "flashMessage--warning";
            break;
        case 3:
            flashClass = "flashMessage--error";
            break;
    }
    const flashMessage = generateNewFlashmessage();
    if (flashMessage) {
        let flashIcon = flashMessage.querySelector(".flashMessage__icon i");
        let flashMsg = flashMessage.querySelector(".flashMessage__msg");
        if(flashIcon){
            flashIcon.setAttribute("class",iconClass);
        }
        if(flashMsg){
            flashMsg.innerText = msg;
        }
        flashMessage.classList.add(flashClass);
        showFlashmessage(flashMessage);

        setTimeout(() => {
            hideFlashmessage(flashMessage);
        }, 4000);
    }
}

export function initFlashmessages(wrapper = document.getElementById('flashMessages')){
    if(wrapper){
        const messages = wrapper.querySelectorAll(".flashMessage");
        let delayShowFlashMessages = 200;
        let delayHideFlashMessages = 6000;
        if (typeof GLOBALS!== "undefined") {
            delayShowFlashMessages = GLOBALS.delayShowFlashMessages;
            delayHideFlashMessages = GLOBALS.delayHideFlashMessages;
        }
        if(messages){
            setTimeout(function (){
                let count = 1;
                for(let msg of messages){
                    if(!msg.classList.contains("is-active")){
                        showFlashmessage(msg,200 * count);
                        count++;
                    }
                }
            },delayShowFlashMessages);

            setTimeout(function (){
                let count = 1;
                for(let msg of messages){
                    if(msg.classList.contains("is-active")){
                        hideFlashmessage(msg,200 * count);
                        count++;
                    }
                }
            },delayHideFlashMessages);
        }
    }
}
/*jshint esversion: 6 */
import {XhrService} from "../../../default/global/ts/xhr.service";
import {addActionClass, addErrorClass, addLoadingClass, addSuccessClass} from "../../../default/global/js/utilities/updateClasses";
import {appendScriptToDOM} from "../../../default/global/js/utilities/functions";
import {getNewFlashmessage} from "../../../default/global/js/flashmessages";
import {checkMandatoryGroups} from "../ts/customization";

function addToFavorites(itemid, beforeSend, successSend, errorSend) {
    const fd = new FormData();
    fd.append("action_id", itemid);

    let customizationItemKeysHiddenInput = document.getElementById('itemcard_order_button_form_std_customization_item_keys');
    if (customizationItemKeysHiddenInput !== null) {
        fd.append("customization_item_keys", customizationItemKeysHiddenInput.value);
    }


    let priceBaseWithoutCustomization = document.getElementById('price_base_without_customization');
    if (priceBaseWithoutCustomization !== null) {
        fd.append("price_base_without_customization", priceBaseWithoutCustomization.innerHTML);
    }

    let priceCustomization = document.getElementById('price_customization');
    if (priceCustomization !== null) {
        fd.append("price_customization", priceCustomization.innerHTML);
    }

    let priceTotal = document.querySelector('.basePriceForJs');
    if (priceTotal !== null) {
        fd.append("price_total", priceTotal.innerText);
    }

    if (typeof beforeSend !== 'undefined') {
        beforeSend();
    }

    const msg = GLOBALS.tc.requestlist_add_text;

    XhrService.generalAjaxCall('add_item_to_favorite', fd, 'POST', 'json').then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
            getNewFlashmessage(msg, 1);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
    })
}

function removeFromFavorites(itemid, beforeSend, successSend, errorSend) {
    const fd = new FormData();
    fd.append("action_id", itemid);

    if (typeof beforeSend !== 'undefined') {
        beforeSend();
    }

    XhrService.generalAjaxCall('remove_item_from_favorite', fd).then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
    });
}

function updateFavoritesCount(add = true) {
    for (let count of document.querySelectorAll('.js-favoritesCount')) {
        let value = count.innerHTML;
        if (value == "") {
            value = 0;
        }
        value = parseInt(value);
        if (add) {
            count.innerHTML = value + 1;
        } else {
            if (value > 0) {
                count.innerHTML = value - 1;
            }
        }
        if (parseInt(count.innerHTML) === 0) {
            count.style.display = "none";
        } else {
            addActionClass(count, function () {
                count.style.display = "";
            });
        }
    }
}

function toggleFavoriteBtnClasses(el) {
    let icon = el.querySelector('.icon');
    let filledIconClass = 'icon-favorites-filled';
    let emptyIconClass = 'icon-favorites';
    if (el.classList.contains("js-addToFavorites")) {
        el.classList.remove("js-addToFavorites");
        el.classList.add("js-removeFromFavorites");
        el.classList.add("active");

        if (icon) {
            icon.classList.remove(emptyIconClass);
            icon.classList.add(filledIconClass);
        }
    } else {
        el.classList.remove("js-removeFromFavorites");
        el.classList.remove("active");
        el.classList.add("js-addToFavorites");

        if (icon) {
            icon.classList.remove(filledIconClass);
            icon.classList.add(emptyIconClass);
        }
    }
}

export function triggerFavoritesBtns() {
    document.addEventListener("click", function (e) {
        let target = e.target;
        let updateCustomization = document.querySelector('.js-customization-updated');

        var newCustomizationApplied = updateCustomization && updateCustomization.value == 1;

        if (target.closest(".js-addToFavorites")) {
            let itemID = target.dataset.id;
            if (itemID) {
                if (document.body.classList.contains("favorites")) {
                    addLoadingClass(target);
                    window.location.href = "?action=shop_add_item_to_favorites&action_id=" + itemID;
                }else{
                    if (!checkMandatoryGroups()) {
                        e.preventDefault();
                        return;
                    }

                    addToFavorites(itemID, () => {
                        addLoadingClass(target);
                    }, (result) => {
                        if (result.successful) {
                            addSuccessClass(target);
                            toggleFavoriteBtnClasses(target);
                            updateFavoritesCount(true);
                            if (result.tagManager) {
                                appendScriptToDOM(result.tagManager, true);
                            }
                        } else {
                            addErrorClass(target);
                        }
                    }, () => {
                        addErrorClass(target);
                    });
                }
            } else {
                console.error("item id not found");
            }
            e.preventDefault();
        }

        if (target.closest(".js-removeFromFavorites") && newCustomizationApplied) {
            let itemID = target.dataset.id;
            if (itemID) {
                if (document.body.classList.contains("favorites")) {
                    addLoadingClass(target);
                    window.location.href = "?action=shop_add_item_to_favorites&action_id=" + itemID;
                }else{
                    if (newCustomizationApplied && !checkMandatoryGroups()) {
                        e.preventDefault();
                        return;
                    }

                    addToFavorites(itemID, () => {
                        addLoadingClass(target);
                    }, (result) => {
                        if (result.successful) {
                            addSuccessClass(target);
                            updateCustomization.value = 0;
                            if (result.tagManager) {
                                appendScriptToDOM(result.tagManager, true);
                            }
                        } else {
                            addErrorClass(target);
                        }
                    }, () => {
                        addErrorClass(target);
                    });
                }
            } else {
                console.error("item id not found");
            }
            e.preventDefault();
        }

        if (target.closest(".js-removeFromFavorites") &&
            (updateCustomization === null || updateCustomization.value == 0 || updateCustomization.value == '')) {
            const itemID = target.dataset.id;
            if (itemID) {
                if (document.body.classList.contains("favorites")) {
                    addLoadingClass(target);
                    window.location.href = "?action=shop_remove_item_from_favorites&action_id=" + itemID;
                } else {
                    removeFromFavorites(itemID, () => {
                        addLoadingClass(target);
                    }, (result) => {
                        addSuccessClass(target);
                        toggleFavoriteBtnClasses(target);
                        updateFavoritesCount(false);
                    }, () => {
                        addErrorClass(target);
                    });
                }
            } else {
                console.error("item id not found");
            }
            e.preventDefault();
        }
    });
}
export function initSearchBar() {
    const searchForm = document.querySelector("form[name='form_search']");
    if (!searchForm) return;

    const searchBar = searchForm.querySelector(".searchBar");
    const searchInput = searchForm.querySelector("input[name='input_search']");
    const searchButton = searchForm.querySelector(".inputGroup__addon");

    searchButton.addEventListener("click", function (event) {
        const isMinified = window.getComputedStyle(searchInput).position === "absolute";
        const isExpanded = searchBar.classList.contains("is-active") || searchBar.classList.contains("is-focus");

        if (isMinified && !isExpanded) {

            event.preventDefault();
            searchBar.classList.add("is-active", "is-focus");
            searchInput.focus();
        } else {

            if (searchInput.value.trim().length === 0) {
                event.preventDefault();
                searchInput.reportValidity();
            }
        }
    });


    searchInput.addEventListener("blur", function () {
        if (searchInput.value.trim().length === 0) {
            searchBar.classList.remove("is-active", "is-focus");
        }
    });
}
import {XhrService} from "../../../default/global/ts/xhr.service";

export function initVehicleSearchForm() {
    const vehicleSearchForm: HTMLFormElement = document.querySelector('.js-vehicle-search-form')
    if (!vehicleSearchForm) {
        return
    }

    const noChoiceLabel = vehicleSearchForm.dataset.noChoiceLabel ?? 'Keine Auswahl verfügbar'

    const brandSelect: HTMLSelectElement = document.querySelector('.js-select-brand')
    const modelSelect: HTMLSelectElement = document.querySelector('.js-select-model')
    const typeSelect: HTMLSelectElement = document.querySelector('.js-select-type')
    const variantSelect: HTMLSelectElement = document.querySelector('.js-select-variant')
    const versionSelect: HTMLSelectElement = document.querySelector('.js-select-version')
    const engineSelect: HTMLSelectElement = document.querySelector('.js-select-engine')

    brandSelect.addEventListener('change', () => {
        const fd = new FormData()
        fd.set('brand', brandSelect.value)
        fd.set('changing', 'brand')
        XhrService.generalAjaxCall('searchVehicles', fd).then(models => {
            modelSelect.innerHTML = ''
            modelSelect.disabled = false
            models.forEach(model => {
                const newModelOption = document.createElement('option')
                newModelOption.value = model.value
                newModelOption.innerText = model.name
                modelSelect.appendChild(newModelOption)
            })
            modelSelect.value = ''

            if (modelSelect.options.length === 0) {
                const noChoiceOption = createNoChoiceOption(noChoiceLabel)
                modelSelect.appendChild(noChoiceOption)
                modelSelect.disabled = true
            }

            typeSelect.value = ''
            typeSelect.disabled = true
            variantSelect.value = ''
            variantSelect.disabled = true
            versionSelect.value = ''
            versionSelect.disabled = true
            engineSelect.value = ''
            engineSelect.disabled = true
        })
    })

    modelSelect.addEventListener('change', () => {
        const fd = new FormData()
        fd.set('brand', brandSelect.value)
        fd.set('model', modelSelect.value)
        fd.set('changing', 'model')
        XhrService.generalAjaxCall('searchVehicles', fd).then(types => {
            typeSelect.innerHTML = ''
            typeSelect.disabled = false
            types.forEach(type => {
                const newTypeOption = document.createElement('option')
                newTypeOption.value = type.value
                newTypeOption.innerText = type.name
                typeSelect.appendChild(newTypeOption)
            })
            typeSelect.value = ''

            if (typeSelect.options.length === 0) {
                const noChoiceOption = createNoChoiceOption(noChoiceLabel)
                typeSelect.appendChild(noChoiceOption)
                typeSelect.disabled = true
            }

            variantSelect.value = ''
            variantSelect.disabled = true
            versionSelect.value = ''
            versionSelect.disabled = true
            engineSelect.value = ''
            engineSelect.disabled = true
        })
    })

    typeSelect.addEventListener('change', () => {
        const fd = new FormData()
        fd.set('brand', brandSelect.value)
        fd.set('model', modelSelect.value)
        fd.set('type', typeSelect.value)
        fd.set('changing', 'type')
        XhrService.generalAjaxCall('searchVehicles', fd).then(variants => {
            variantSelect.innerHTML = ''
            variantSelect.disabled = false
            variants.forEach(variant => {
                const newVariantOption = document.createElement('option')
                newVariantOption.value = variant.value
                newVariantOption.innerText = variant.name
                variantSelect.appendChild(newVariantOption)
            })
            variantSelect.value = ''

            if (variantSelect.options.length === 0) {
                const noChoiceOption = createNoChoiceOption(noChoiceLabel)
                variantSelect.appendChild(noChoiceOption)
                variantSelect.disabled = true
            }

            versionSelect.value = ''
            versionSelect.disabled = true
            engineSelect.value = ''
            engineSelect.disabled = true
        })
    })

    variantSelect.addEventListener('change', () => {
        const fd = new FormData()
        fd.set('brand', brandSelect.value)
        fd.set('model', modelSelect.value)
        fd.set('type', typeSelect.value)
        fd.set('variant', variantSelect.value)
        fd.set('changing', 'variant')
        XhrService.generalAjaxCall('searchVehicles', fd).then(versions => {
            versionSelect.innerHTML = ''
            versionSelect.disabled = false
            versions.forEach(version => {
                const newVersionOption = document.createElement('option')
                newVersionOption.value = version.value
                newVersionOption.innerText = version.name
                versionSelect.appendChild(newVersionOption)
            })
            versionSelect.value = ''

            if (versionSelect.options.length === 0) {
                const noChoiceOption = createNoChoiceOption(noChoiceLabel)
                versionSelect.appendChild(noChoiceOption)
                versionSelect.disabled = true
            }

            engineSelect.value = ''
            engineSelect.disabled = true
        })
    })

    versionSelect.addEventListener('change', () => {
        const fd = new FormData()
        fd.set('brand', brandSelect.value)
        fd.set('model', modelSelect.value)
        fd.set('type', typeSelect.value)
        fd.set('variant', variantSelect.value)
        fd.set('version', versionSelect.value)
        fd.set('changing', 'version')
        XhrService.generalAjaxCall('searchVehicles', fd).then(engines => {
            engineSelect.innerHTML = ''
            engineSelect.disabled = false
            engines.forEach(engine => {
                const newEngineOption = document.createElement('option')
                newEngineOption.value = engine.value
                newEngineOption.innerText = engine.name
                engineSelect.appendChild(newEngineOption)
            })
            engineSelect.value = ''

            if (engineSelect.options.length === 0) {
                const noChoiceOption = createNoChoiceOption(noChoiceLabel)
                engineSelect.appendChild(noChoiceOption)
                engineSelect.disabled = true
            }
        })
    })

    function createNoChoiceOption(noChoiceLabel: string) {
        const newTypeOption = document.createElement('option')
        newTypeOption.value = ''
        newTypeOption.innerText = noChoiceLabel
        newTypeOption.selected = true
        return newTypeOption
    }
}

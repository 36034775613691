import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

/**
 * Initialisiert Swiper für alle Elemente mit der Klasse `.swiperContentBox`.
 */
export function initContentBoxSwiper() {
    const swiperContainers = document.querySelectorAll('.swiperContentBox');

    // Überprüfen, ob Container gefunden wurden
    if (swiperContainers.length === 0) {
        return;
    }

    // Für jeden Container Swiper initialisieren
    swiperContainers.forEach((swiperContainer, index) => {
        // Übergeordnetem Container eine relative Klasse hinzufügen
        const parentContainer = swiperContainer.parentElement;
        if (parentContainer) {
            parentContainer.classList.add('swiper-parent-relative');
        }

        // Wrapper erstellen und Elemente als Slides einfügen
        const slides = swiperContainer.querySelectorAll(':scope > .gp');
        const swiperWrapper = document.createElement('div');
        swiperWrapper.classList.add('swiper-wrapper');

        slides.forEach(slide => {
            slide.classList.add('swiper-slide');
            swiperWrapper.appendChild(slide);
        });

        // Den alten Inhalt durch den neuen Wrapper ersetzen
        swiperContainer.appendChild(swiperWrapper);

        // Navigation-Buttons erstellen und außerhalb des Containers platzieren
        const nextButton = document.createElement('div');
        nextButton.classList.add('swiper-button-next', `swiper-button-next-${index}`);
        parentContainer.appendChild(nextButton); // Platzierung im übergeordneten Container

        const prevButton = document.createElement('div');
        prevButton.classList.add('swiper-button-prev', `swiper-button-prev-${index}`);
        parentContainer.appendChild(prevButton); // Platzierung im übergeordneten Container

        // Pagination hinzufügen (innerhalb des Containers)
        if (!swiperContainer.querySelector('.swiper-pagination')) {
            const pagination = document.createElement('div');
            pagination.classList.add('swiper-pagination');
            swiperContainer.appendChild(pagination);
        }

        // Initialisiere Swiper für den aktuellen Container
        new Swiper(swiperContainer, {
            slidesPerView: 3, // Standard: 3 Slides
            initialSlide: 1,
            centeredSlides: true,
            spaceBetween: 16,
            navigation: {
                nextEl: `.swiper-button-next-${index}`, // Buttons für jeden Swiper eindeutig
                prevEl: `.swiper-button-prev-${index}`,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
                480: {
                    slidesPerView: 1.15,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 3,
                },
            },
        });
    });
}
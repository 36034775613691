export function sortAllSelectOptions() {
    // Alle Elemente mit der Klasse .js-selectOptions auswählen
    const allSelectOptions = document.querySelectorAll('.js-selectOptions');

    allSelectOptions.forEach(selectOptions => {
        // Alle Items innerhalb des aktuellen .js-selectOptions Elements auswählen, außer das Footer-Element
        const items = Array.from(selectOptions.querySelectorAll('.customMultiSelect__selectbox--item'));

        // Footer-Element aus der Liste entfernen, falls vorhanden
        const footer = selectOptions.querySelector('.customCollectionSelect__dropdownFooter');
        if (footer) {
            selectOptions.removeChild(footer);
        }

        // Sortiere die Items basierend auf dem data-sort Attribut
        items.sort((a, b) => {
            const textA = a.getAttribute('data-sort').toLowerCase();
            const textB = b.getAttribute('data-sort').toLowerCase();

            // Alphabetische Sortierung (aufsteigend)
            if (textA < textB) return -1;
            if (textA > textB) return 1;
            return 0;
        });

        // Die sortierten Items wieder in das selectOptions-Element einfügen
        items.forEach(item => {
            selectOptions.appendChild(item);
        });

        // Das Footer-Element wieder ans Ende der Liste anfügen
        if (footer) {
            selectOptions.appendChild(footer);
        }
    });
}

const settings = {
    container: 1638,
    container2: 1360,
    container3: 980,
    grid: {
        gap: 32,
        gapMobil: 16,
        screenXS: 481,
        screenSM: 768,
        screenMD: 1024,
        screenLG: 1250,
        screenXL: 1670
    },
    tc: {
        minutes: "Minuten",
        minute: "Minute"
    },
    breakpointMobileMenu: 1023,
    defaultLanguage: "de",
}

export default settings;
export function setMenuHeight() {
    // Holen Sie sich das Element mit der Klasse 'js-megamenu'
    var megamenu = document.querySelector('.js-megamenu ul.level_2');

    // Überprüfen Sie, ob das Element existiert und nicht im Footer ist
    if (megamenu && !megamenu.closest('.footerMain')) {
        // Holen Sie sich die Höhe des Elements
        var megamenuHeight = megamenu.offsetHeight;

        // Holen Sie sich alle Elemente mit der Klasse 'js-menuHeight'
        var menuHeightElements = document.querySelectorAll('.js-menuHeight');

        menuHeightElements.forEach(function(element) {
            // Überprüfen Sie, ob das Element nicht im Footer ist
            if (!element.closest('.footerMain')) {
                // Setzen Sie die min-height jedes Elements auf die Höhe des 'js-megamenu'-Elements
                element.style.minHeight = megamenuHeight + 'px';
                element.style.maxHeight = (megamenuHeight - 40) + 'px';
                element.style.overflow = 'auto';
                element.style.flexWrap = 'nowrap';
            }
        });
    }
}

export function resetMenuHeight() {
    // Holen Sie sich das Element mit der Klasse 'js-megamenu'
    var megamenu = document.querySelector('.js-megamenu ul.level_2');

    // Überprüfen Sie, ob das Element existiert und nicht im Footer ist
    if (megamenu && !megamenu.closest('.footerMain')) {
        // Holen Sie sich die Höhe des Elements
        var megamenuHeight = megamenu.offsetHeight;

        // Holen Sie sich alle Elemente mit der Klasse 'js-menuHeight'
        var menuHeightElements = document.querySelectorAll('.js-menuHeight');

        menuHeightElements.forEach(function(element) {
            // Überprüfen Sie, ob das Element nicht im Footer ist
            if (!element.closest('.footerMain')) {
                // Setzen Sie die min-height jedes Elements auf 0
                element.style.minHeight = '0px';
            }
        });
    }
}

export function setMenuHeightContent() {
    // Holen Sie sich das Element mit der Klasse 'js-megamenu_content'
    var megamenu = document.querySelector('.js-megamenu_content ul.level_1');

    // Überprüfen Sie, ob das Element existiert und nicht im Footer ist
    if (megamenu && !megamenu.closest('.footerMain')) {
        // Holen Sie sich die Höhe des Elements
        var megamenuHeight = megamenu.offsetHeight;

        // Holen Sie sich alle Elemente mit der Klasse 'js-menuHeight-content'
        var menuHeightElements = document.querySelectorAll('.js-menuHeight-content');

        menuHeightElements.forEach(function(element) {
            // Überprüfen Sie, ob das Element nicht im Footer ist
            if (!element.closest('.footerMain')) {
                // Setzen Sie die min-height und max-height jedes Elements
                element.style.minHeight = '140px';
                element.style.maxHeight = '140px';
                element.style.overflow = 'auto';
                element.style.flexWrap = 'nowrap';
            }
        });
    }
}

export function resetMenuHeightContent() {
    // Holen Sie sich das Element mit der Klasse 'js-megamenu_content'
    var megamenu = document.querySelector('.js-megamenu_content ul.level_1');

    // Überprüfen Sie, ob das Element existiert und nicht im Footer ist
    if (megamenu && !megamenu.closest('.footerMain')) {
        // Holen Sie sich die Höhe des Elements
        var megamenuHeight = megamenu.offsetHeight;

        // Holen Sie sich alle Elemente mit der Klasse 'js-menuHeight-content'
        var menuHeightElements = document.querySelectorAll('.js-menuHeight-content');

        menuHeightElements.forEach(function(element) {
            // Überprüfen Sie, ob das Element nicht im Footer ist
            if (!element.closest('.footerMain')) {
                // Setzen Sie die min-height jedes Elements auf 0
                element.style.minHeight = '0px';
            }
        });
    }
}
declare var GLOBALS: any;

import { getNewFlashmessage } from '../../../default/global/js/flashmessages.js';
export function initCustomization() {
    const addOns: IAddon[] = [];

    document.querySelectorAll<HTMLDivElement>('.js-addOnItem').forEach(addOnItem => {
        const addOnItemInput = addOnItem.querySelector<HTMLInputElement>('.js-item-selection');
        const addOnQtySelection = addOnItem.querySelector<HTMLInputElement>('.js-quantity-selection')
        const mappableAddOnItem: IAddon = {
            requiredItemNo: addOnItem.dataset.requiredItemNo,
            itemNo: addOnItem.dataset.itemNo,
            itemId: parseInt(addOnItem.dataset.itemId),
            unitPrice: parseFloat(addOnItem.dataset.unitPrice) ?? 0,
            mandatory: addOnItem.dataset.mandatory === '1',
            preselected: addOnItem.dataset.preselected === '1',
            inputField: addOnItemInput,
            divWrapper: addOnItem,
            key: addOnItem.dataset.key,
            quantityField: addOnQtySelection,
            preselectedQuantity: parseInt(addOnItem.dataset.qty) ?? 1
        }
        addOns.push(mappableAddOnItem);
        addOnItemInput.addEventListener('change', () => {
            updateInputs();
            document.querySelector<HTMLInputElement>('.js-customization-updated').value = '1'
        });
        addOnQtySelection?.addEventListener('change', () => {
            if (addOnQtySelection.value < 1) {
                addOnItemInput.checked = false
            }
            updateInputs()

            document.querySelector<HTMLInputElement>('.js-customization-updated').value = '1'
        })
    });

    if (addOns.length > 0) {
        updateInputs(true);
    }

    function updateInputs(initial = false) {
        let updatePrice = 0.0
        const updateIds: string[] = []
        const updateKeys: string[] = []
        addOns.forEach((addOnItem) => {
            const requiredAddOnItems = addOns.filter(ao => ao.itemNo === addOnItem.requiredItemNo)
            let requiredAddOnItem = requiredAddOnItems.find(ao => ao.inputField.checked)
            if (requiredAddOnItems.length && !requiredAddOnItem) {
                requiredAddOnItem = requiredAddOnItems[0]
            }
            if (requiredAddOnItem) {
                const wasVisible = !addOnItem.divWrapper.classList.contains('hidden')
                addOnItem.divWrapper.classList.toggle('hidden', !requiredAddOnItem.inputField.checked);
                const isVisible = !addOnItem.divWrapper.classList.contains('hidden')

                if (requiredAddOnItem.inputField.checked) {
                    if (!initial) {
                        if (addOnItem.mandatory || (addOnItem.preselected && isVisible && !wasVisible)) {
                            addOnItem.inputField.checked = true
                            addOnItem.inputField.dataset.previouslyChecked = 'true'
                        }
                    }
                } else {
                    addOnItem.inputField.checked = false
                    addOnItem.inputField.dataset.previouslyChecked = 'false'
                }
            }
            if (addOnItem.inputField.checked) {
                if (addOnItem.quantityField) {
                    addOnItem.quantityField.disabled = false
                    if (parseInt(addOnItem.quantityField.value) < 1) {
                        addOnItem.quantityField.value = addOnItem.preselectedQuantity.toString()
                    }
                }
                let addOnPrice = 0
                if (addOnItem.unitPrice) {
                    addOnPrice = addOnItem.unitPrice * (addOnItem.quantityField ? parseInt(addOnItem.quantityField.value) : 1)
                }
                updatePrice += addOnPrice
                updateIds.push(`${addOnItem.itemId};;;${addOnItem.quantityField?.value || 1}`)
                updateKeys.push(`${addOnItem.key};;;${addOnItem.quantityField?.value || 1}`)
            } else {
                if (addOnItem.quantityField) {
                    addOnItem.quantityField.disabled = true
                    addOnItem.quantityField.value = '0'
                }
            }
        })

        updateBackgroundColors()

        let basePrice = 0.00;
        const basePriceNode = document.querySelector('#itemcard_order_button_form_std .basePrice span[itemprop=price]');
        let currencyCode = 'CHF';
        if (basePriceNode) {
            basePrice = parseFloat(document.querySelector('#itemcard_order_button_form_std .basePrice span[itemprop=price]').getAttribute('content')) ?? 0
            const custPrice = basePrice + updatePrice;

            let priceNode = document.querySelector('#itemcard_order_button_form_std .basePrice').lastChild;
            currencyCode = document.querySelector('#itemcard_order_button_form_std .basePrice span[itemprop=priceCurrency]').getAttribute('content');


            priceNode.replaceWith(new Text(formatPrice(custPrice, currencyCode)));
        } else {
            basePrice = parseFloat(document.querySelector('.basePrice').getAttribute('content'));
            if (Number.isNaN(basePrice)) {
                basePrice = parseFloat(document.querySelector('.basePriceForJs').getAttribute('content'));
            }

            if (Number.isNaN(basePrice)) {
                return;
            }

            let custPrice = basePrice + updatePrice;

            let priceNode = document.querySelector('.basePriceForJs').lastChild;

            priceNode.replaceWith(new Text(formatPrice(custPrice, currencyCode)));
        }


        const addOnAmount = document.querySelector('.js-addOnPrice');

        addOnAmount.innerHTML = formatPrice(updatePrice, currencyCode);
        document.querySelector<HTMLInputElement>('.js-customizedItemIds').value = updateIds.join(',');
        document.querySelector<HTMLInputElement>('.js-customizedItemKeys').value = updateKeys.join(',');
    }

    function formatPrice(price: number, currencyCode: string) {
        return price.toLocaleString(
            'de-CH',
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'currency',
                currency: currencyCode
            })
    }

    function enableDeselectForRadios(radioNamePrefix: string) {
        // Wählt alle Radio-Buttons aus, deren name mit `radioNamePrefix` beginnt
        const radios = document.querySelectorAll<HTMLInputElement>(`input[name^="${radioNamePrefix}"]`);

        radios.forEach(radio => {
            radio.addEventListener('click', function (event) {
                if (radio.dataset.previouslyChecked === 'true') {
                    this.checked = false;
                    radio.dataset.previouslyChecked = 'false';
                } else {
                    radios.forEach(r => r.dataset.previouslyChecked = 'false');
                    radio.dataset.previouslyChecked = 'true';
                }
                updateInputs();
            });

            radio.dataset.previouslyChecked = 'false';
        });
    }

    function updateBackgroundColors() {
        document.querySelectorAll<HTMLDivElement>(".js-addOnItem:not(.hidden)").forEach((item, index) => {
            if (index % 2 === 0) {
                item.style.backgroundColor = "#f0f0f0";
            } else {
                item.style.backgroundColor = "transparent";
            }
        })
    }

    // Beispiel: Anwenden auf Radio-Buttons mit dem Namen "addOn_radio"
    enableDeselectForRadios("addOn_radio");
}

export function checkMandatoryGroups(): boolean {
    const mandatoryGroups = document.querySelectorAll<HTMLDivElement>('.js-mandatory-group');
    const emptyGroups: HTMLDivElement[] = [];

    mandatoryGroups.forEach(group => {
        if (!group.querySelector('input:checked')) {
            emptyGroups.push(group);
        } else {
            group.classList.remove('sparePartsListContainer--alert');
        }
    });

    if (emptyGroups.length > 0) {
        emptyGroups.forEach(g => g.classList.add('sparePartsListContainer--alert'));

        emptyGroups[0].scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
            window.scrollBy({
                top: -200,
                behavior: 'smooth'
            });
        }, 200);

        const msg = GLOBALS.tc.mandatoryGroupNotSelected ?? 'Sie müssen noch Artikel auswählen';
        getNewFlashmessage(msg, 2);
        return false;
    }

    return true;
}

interface IAddon {
    requiredItemNo: string,
    itemNo: string,
    itemId: number,
    unitPrice: number,
    mandatory: boolean,
    preselected: boolean,
    inputField: HTMLInputElement,
    divWrapper: HTMLDivElement,
    key: string,
    quantityField: HTMLInputElement | undefined,
    preselectedQuantity: number
}

import {slideDown, slideUp} from "../../../default/global/js/utilities/slide";

function openSlidecontentItem(slidecontentItem) {
    slidecontentItem.classList.add("is-active");
    let content = slidecontentItem.querySelector(".slidecontentItem__content");
    if (content) {
        slideDown(content)
    }
}

function closeSlidecontentItem(slidecontentItem) {
    slidecontentItem.classList.remove("is-active");
    let content = slidecontentItem.querySelector(".slidecontentItem__content");
    if (content) {
        slideUp(content);
    }
}

export function initSlidecontents(query = document.querySelectorAll('.slidecontentItem__headline')) {
    for (let el of query) {
        let slidecontentItem = el.closest('.slidecontentItem');

        el.addEventListener("click", function () {
            // Prüfen, ob das Element die Klasse `--noToggle` enthält und der Bildschirm größer als 768px ist
            if (el.classList.contains('slidecontentItem__headline--noToggle') && window.innerWidth > 768) {
                return; // Funktion nicht ausführen
            }

            if (slidecontentItem.classList.contains("is-active")) {
                closeSlidecontentItem(slidecontentItem);
            } else {
                openSlidecontentItem(slidecontentItem);
            }
        });
    }
}

export function toggleMaxHeight() {
    document.querySelectorAll(".js-showSlidecontent").forEach(button => {
        const content = button.closest(".itemcardCustomization").querySelector(".slidecontentItem__content");

        if (content) {
            if (content.scrollHeight > 400) {
                button.classList.remove("d-none");
            }

            button.addEventListener("click", function () {
                if (content.style.maxHeight === "unset") {
                    content.style.maxHeight = "";
                    this.textContent = "Aufklappen";
                } else {
                    content.style.maxHeight = "unset";
                    this.textContent = "Zuklappen";
                }
            });
        }
    });
}

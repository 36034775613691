export function initSparrechner() {
    const savingsCalculator = document.querySelector('#savingscalculator');
    if (!savingsCalculator) {
        return
    }

    savingsCalculator.querySelectorAll('input').forEach(sparRechnerInputs => {
        sparRechnerInputs.addEventListener("change", function () {
            calculateSavings(savingsCalculator);
        });
    });
}

function calculateSavings(savingsCalculator) {
    const currentMethod = savingsCalculator.current_method.value ?? false

    const compareWith = savingsCalculator.compare_with.value ?? false

    const quantity = parseFloat(savingsCalculator.querySelector('.js-savingscalculator-amount').value.replace(',', '.'))
    const price = parseFloat(savingsCalculator.querySelector('.js-savingscalculator-price').value.replace(',', '.'))
    let percent = 0

    if (currentMethod === 'dry_salt' && compareWith === 'compare_pre_humidification') {
        percent = 0.433
    } else if (currentMethod === 'dry_salt' && compareWith === 'only_brine') {
        percent = 0.743
    } else if (currentMethod === 'pre_humidification' && compareWith === 'only_brine') {
        percent = 0.547
    } else {
        percent = 0
    }

    if (!isNaN(quantity) && !isNaN(price)) {
        const saving = quantity * price * percent;
        savingsCalculator.querySelector('.js-result').innerHTML = "CHF " + saving.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }
}

// D-Value Calculation
const totalWeightInput = document.getElementById('total-weight');
const trailerWeightInput = document.getElementById('trailer-weight');
const dValueSpan = document.getElementById('d-value');
const supportLoadSpan = document.getElementById('support-load');

const totalWeightInput2 = document.getElementById('total-weight-2');
const dValueInput2 = document.getElementById('d-value-2');
const trailerLoadSpan = document.getElementById('trailer-load');
const supportLoadSpan2 = document.getElementById('support-load-2');

const trailerWeightInput2 = document.getElementById('trailer-weight-2');
const dValueInput3 = document.getElementById('d-value-3');
const totalWeightSpan = document.getElementById('total-weight-3');
const supportLoadSpan3 = document.getElementById('support-load-3');

export function calculateDValue() {
    const totalWeight = parseFloat(totalWeightInput.value);
    const trailerWeight = parseFloat(trailerWeightInput.value);

    if (!isNaN(totalWeight) && !isNaN(trailerWeight)) {
        // Calculate D-Value
        let dValue = ((totalWeight * trailerWeight * 9.81) / (totalWeight + trailerWeight) / 1000);
        dValue = Math.max(dValue, 0); // Ensure dValue is not negative
        dValueSpan.textContent = dValue.toFixed(2) + ' kN';

        // Calculate Support Load (assumed 4% of trailer weight)
        const supportLoad = Math.max(trailerWeight * 0.04, 0); // Ensure supportLoad is not negative
        supportLoadSpan.textContent = 'max. ' + supportLoad.toFixed(2) + ' kg';
    }
}

if(totalWeightInput) {
    totalWeightInput.addEventListener('keyup', calculateDValue);
}

if(trailerWeightInput) {
    trailerWeightInput.addEventListener('keyup', calculateDValue);
}


// Trailer Load Calculation


export function calculateTrailerLoad() {
    const totalWeight2 = parseFloat(totalWeightInput2.value);
    const dValue2 = parseFloat(dValueInput2.value);

    if (!isNaN(totalWeight2) && !isNaN(dValue2) && dValue2 > 0) {
        // Calculate Trailer Load
        let trailerLoad = ((dValue2 * 1000) * totalWeight2) / (totalWeight2 * 9.81 - (dValue2 * 1000));
        trailerLoad = Math.max(trailerLoad, 0); // Ensure trailerLoad is not negative
        trailerLoadSpan.textContent = 'max. ' + trailerLoad.toFixed(2) + ' kg';

        // Calculate Support Load (assumed 4% of trailer load)
        const supportLoad2 = Math.max(trailerLoad * 0.04, 0); // Ensure supportLoad2 is not negative
        supportLoadSpan2.textContent = 'max. ' + supportLoad2.toFixed(2) + ' kg';
    } else {
        // Handle case where dValue2 <= 0
        trailerLoadSpan.textContent = 'max. 0.00 kg';
        supportLoadSpan2.textContent = 'max. 0.00 kg';
    }
}

if(totalWeightInput2) {
    totalWeightInput2.addEventListener('keyup', calculateTrailerLoad);
}

if(dValueInput2) {
    dValueInput2.addEventListener('keyup', calculateTrailerLoad);
}



// Total Weight Calculation


export function calculateTotalWeight() {
    const trailerWeight2 = parseFloat(trailerWeightInput2.value);
    const dValue3 = parseFloat(dValueInput3.value);

    if (!isNaN(trailerWeight2) && !isNaN(dValue3) && dValue3 > 0) {
        // Calculate Total Weight
        let totalWeight3 = ((dValue3 * 1000) * trailerWeight2) / (trailerWeight2 * 9.81 - (dValue3 * 1000));
        totalWeight3 = Math.max(totalWeight3, 0); // Ensure totalWeight3 is not negative
        totalWeightSpan.textContent = totalWeight3.toFixed(2) + ' kg';

        // Calculate Support Load (assumed 4% of total weight)
        const supportLoad3 = Math.max(totalWeight3 * 0.04, 0); // Ensure supportLoad3 is not negative
        supportLoadSpan3.textContent = 'max. ' + supportLoad3.toFixed(2) + ' kg';
    } else {
        // Handle case where dValue3 <= 0
        totalWeightSpan.textContent = 'max. 0.00 kg';
        supportLoadSpan3.textContent = 'max. 0.00 kg';
    }
}

if(trailerWeightInput2) {
    trailerWeightInput2.addEventListener('keyup', calculateTotalWeight);
}

if(dValueInput3) {
    dValueInput3.addEventListener('keyup', calculateTotalWeight);
}


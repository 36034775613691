
function triggerEvents(input) {
    input.dispatchEvent(new Event('input', { bubbles: true }));
    input.dispatchEvent(new Event('change', { bubbles: true }));
    input.dispatchEvent(new KeyboardEvent('keydown', { bubbles: true }));
    input.dispatchEvent(new KeyboardEvent('keyup', { bubbles: true }));
}

function spinUp(input) {
    if (!input) return;

    let val = parseInt(input.value) || 0;
    let max = input.hasAttribute('max') ? parseInt(input.getAttribute('max')) : Infinity;
    let step = input.hasAttribute('step') ? parseInt(input.getAttribute('step')) : 1;

    if (input.disabled || input.readOnly) return;

    let newValue = val + step - (val % step);

    if (newValue > max) {
        newValue = max;
    }

    input.value = newValue;

    setTimeout(() => triggerEvents(input), 10); // Verzögerung zur Verarbeitung
}

function spinDown(input) {
    if (!input) return;

    let val = parseInt(input.value) || 0;
    let min = input.hasAttribute('min') ? parseInt(input.getAttribute('min')) : -Infinity;
    let step = input.hasAttribute('step') ? parseInt(input.getAttribute('step')) : 1;

    if (input.disabled || input.readOnly) return;

    let newValue = val - step;

    if (newValue < min) {
        newValue = min;
    }

    input.value = newValue;

    setTimeout(() => triggerEvents(input), 10);
}

export function getAllQuantityInputs(){
    return document.querySelectorAll('.quantity input[type="number"]');
}

export function initQuantitySpinners(done = function () {
}) {
    document.addEventListener("click", function (e) {
        let target = e.target;
        let quantity = target.closest('.quantity');
        if (quantity) {
            let input = target.closest('.quantity').querySelector('input[type="number"]');
            if (input) {
                if (target.closest('.js-spinnerUp')) {
                    spinUp(input);
                    done(input);
                }
                if (target.closest('.js-spinnerDown')) {
                    spinDown(input);
                    done(input);
                }
            }
        }
    });
}
import settings from "./settings";

function playVideo(video) {
    let bannerWrapper = video.closest('.banner');

    if(bannerWrapper.classList.contains('active')){
        video.pause();
        video.closest('.videoplaywrapper').classList.remove('active');
        bannerWrapper.classList.remove('active');
        if(bannerWrapper){
            bannerWrapper.querySelector('.bannerTextRight').style.display = "";
        }

        for(let playbtn of bannerWrapper.querySelectorAll('.playbtn')){
            playbtn.classList.remove('active');
        }
    }else{
        video.play();
        video.closest('.videoplaywrapper').classList.add('active');
        bannerWrapper.classList.add('active');
        bannerWrapper.querySelector('.bannerTextRight').style.display = "none";

        for(let playbtn of bannerWrapper.querySelectorAll('.playbtn')){
            playbtn.classList.add('active');
        }
    }
}

function muteVideo(video,mutebtn) {
    if(video.muted){
        video.muted = false;
        mutebtn.classList.remove('active');
    }else{
        video.muted = true;
        mutebtn.classList.add('active');
    }
}

function switchVideoName(video,from,to){
    for(let source of video.querySelectorAll('source')){
        let src = source.src;
        source.src = src.replace(from,to);
    }
    video.load();
}

export function generateVideoplayButton(video) {
    video.closest('div').classList.add('videoplaywrapper');
    let longName = video.dataset.longName;
    let shortName = video.dataset.shortName;
    if(longName === undefined){
        video.removeAttribute('muted');
        video.removeAttribute('autoplay');
    }
    let bannerWrapper = video.closest('.banner');

    if(bannerWrapper){
        bannerWrapper.classList.add('banner--video');
    }

    let playbutton = document.createElement('div');
    playbutton.classList.add('playbtn');
    playbutton.innerHTML = "<i class='icon icon-play'></i>";
    video.after(playbutton);

    let mutebutton = document.createElement('div');
    mutebutton.classList.add('mutebtn');
    mutebutton.innerHTML = "<i class='icon icon-volume'></i>";
    video.after(mutebutton);

    for(let playbtn of bannerWrapper.querySelectorAll('.playbtn')){
        playbtn.addEventListener('click',function () {
            if(longName && shortName){
                video.removeAttribute('muted');
                video.removeAttribute('autoplay');
                switchVideoName(video,shortName,longName);
                video.muted = false;
            }

            playVideo(video);
        });
    }

    mutebutton.addEventListener('click',function () {
        muteVideo(video,mutebutton);
    });
}


export function setSrcVideos() {
    const windowWidth = window.outerWidth;

    for (let video of document.querySelectorAll('video')) {
        for (let videoSource of video.querySelectorAll('source')) {
            let src = false;
            let srcDesktop = videoSource.dataset.src;
            let srcTablet = videoSource.dataset.tabletSrc;
            let srcMobile = videoSource.dataset.mobileSrc;

            if (srcDesktop !== undefined) {
                src = srcDesktop;
            }
            if (windowWidth < settings.grid.screenMD && srcTablet !== undefined) {
                src = srcTablet;
            }
            if (windowWidth < settings.grid.screenSM && srcMobile !== undefined) {
                src = srcMobile;
            }

            if (src !== false) {
                videoSource.setAttribute('src', src);
            }
        }
        video.load();
    }
}
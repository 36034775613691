import Swiper, {Autoplay, Navigation,Pagination} from 'swiper';
import {getOptionsFromJsonString} from "./utilities/options";

export function initSlideshows(query = document.querySelectorAll('.slideshow')) {
    for (let slideshow of query) {
        let options = getOptionsFromJsonString(slideshow.dataset.options, {
            autoplay_interval: 5000,
            eff_interval: 300,
        });

        Swiper.use([Autoplay, Navigation,Pagination]);
        new Swiper(slideshow, {
            loop: true,
            items: 1,
            speed: options.eff_interval,
            autoplay: {
                delay: options.autoplay_interval,
            },
            navigation: {
                nextEl: slideshow.querySelector('.swiper-button-next'),
                prevEl: slideshow.querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
        });
    }
}

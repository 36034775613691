
function spinUp(input) {
    if (input) {
        let val = parseInt(input.value);
        let newValue = val;
        let max = 0;
        let step = 1;
        let isDisabled = false;

        if (input.getAttribute('max')) {
            max = parseInt(input.getAttribute('max'));
        }

        if (input.getAttribute('step')) {
            step = parseInt(input.getAttribute('step'));
        }

        if (input.disabled) {
            isDisabled = true;
        }

        if (input.readonly) {
            isDisabled = true;
        }

        if (!isDisabled) {
            if (max !== undefined && max > 0) {
                if (max >= val + step - (val % step)) {
                    newValue = val + step - (val % step);
                }
            } else {
                newValue = val + step - (val % step);
            }
        }

        input.value = newValue;
    }
}

function spinDown(input) {
    if (input) {
        let val = parseInt(input.value);
        let newValue = val;
        let min = 0;
        let step = 1;
        let isDisabled = false;

        if (input.getAttribute('min')) {
            min = parseInt(input.getAttribute('min'));
        }

        if (input.getAttribute('step')) {
            step = parseInt(input.getAttribute('step'));
        }

        if (input.disabled) {
            isDisabled = true;
        }

        if (input.readonly) {
            isDisabled = true;
        }

        if (!isDisabled) {
            if (((val - step) % step) > 0) {
                if (min <= val - (val % step)) {
                    newValue = val - (val % step);
                }
            } else {
                if (min <= val - step) {
                    newValue = val - step;
                }
            }
        }

        input.value = newValue;
    }
}

export function getAllQuantityInputs(){
    return document.querySelectorAll('.quantity input[type="number"]');
}

export function initQuantitySpinners(done = function () {
}) {
    document.addEventListener("click", function (e) {
        let target = e.target;
        let quantity = target.closest('.quantity');
        if (quantity) {
            let input = target.closest('.quantity').querySelector('input[type="number"]');
            if (input) {
                if (target.closest('.js-spinnerUp')) {
                    spinUp(input);
                    done(input);
                }
                if (target.closest('.js-spinnerDown')) {
                    spinDown(input);
                    done(input);
                }
            }
        }
    });
}
import Swiper, {Autoplay, Pagination, HashNavigation} from 'swiper';

export function testimonialSwiper() {
    Swiper.use([Autoplay, Pagination, HashNavigation]);
    var testimonialSwiper = new Swiper(".slideshow--testimonials", {
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        hashNavigation: {
            watchState: true,
        },
        autoplay: false,
        loop: false,
    });
}


export function itemcardTabs() {
    let tabWrapper = document.querySelector('.itemcardTabselect');

    if (tabWrapper) {
        let selectors = tabWrapper.querySelectorAll('.itemcardTabselect__selectors span');
        let tabs = tabWrapper.querySelectorAll('.itemcardTabselect__tabs > div');

        if (selectors && tabs) {
            selectors[0].parentElement.classList.add('activeTab');
            tabs[0].classList.add('activeTab');

            tabs.forEach((tabItem, index) => {
                tabItem.classList.add('tabSelector-' + index);
            });

            selectors.forEach((selectorItem, index) => {
                selectorItem.classList.add('tabSelector-' + index);
                selectorItem.addEventListener('click', function() {
                    if (selectors.constructor === tabs.constructor) {
                        tabs.forEach((tabItem, index) => {
                            tabItem.classList.remove('activeTab');
                        });
                        selectors.forEach((selectorItem, index) => {
                            selectorItem.parentElement.classList.remove('activeTab');
                        });
                        selectors[index].parentElement.classList.add('activeTab');
                        tabs[index].classList.add('activeTab');
                    }
                })
            });
        }
    }
}